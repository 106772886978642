import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import SvgIcon from "@mui/material/SvgIcon";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Download01 } from "@untitled-ui/icons-react/build/esm";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { FC } from "react";
import { useTillSummary } from "~/hooks/use-till-summary";
import { useGetCurrentCompany } from "~/queries/useGetCurrentCompany";
import { useGetCurrentFile } from "~/queries/useGetFileById";
import { useGetProductOptions } from "~/queries/useGetProductOptions";
import { useGetProducts } from "~/queries/useGetProducts";
import { OrderSheetDocument } from "./order-sheet-document";
export const OrderSheetDialog: FC<{
  onClose: () => void;
}> = ({
  onClose
}) => {
  const {
    data: company
  } = useGetCurrentCompany();
  const {
    data: file
  } = useGetCurrentFile();
  const {
    updateFileInputSummary
  } = useTillSummary();
  const {
    data: products = []
  } = useGetProducts();
  const {
    data: productOptions = []
  } = useGetProductOptions();
  if (!file || !company) return null;
  return <Dialog open fullScreen data-sentry-element="Dialog" data-sentry-component="OrderSheetDialog" data-sentry-source-file="order-sheet-dialog.tsx">
			<Box sx={{
      display: "flex",
      flexDirection: "column",
      height: "100%"
    }} data-sentry-element="Box" data-sentry-source-file="order-sheet-dialog.tsx">
				<Stack direction="row" sx={{
        justifyContent: "space-between",
        backgroundColor: "background.paper",
        p: 2
      }} data-sentry-element="Stack" data-sentry-source-file="order-sheet-dialog.tsx">
					<Button color="inherit" startIcon={<SvgIcon>
								<ArrowLeftIcon />
							</SvgIcon>} onClick={onClose} data-sentry-element="Button" data-sentry-source-file="order-sheet-dialog.tsx">
						Close
					</Button>
					{!!file && !!company && <PDFDownloadLink document={<OrderSheetDocument itemsSummary={updateFileInputSummary} file={file} company={company} products={products} productOptions={productOptions} />} fileName="order-sheet" style={{
          textDecoration: "none"
        }}>
							<Button startIcon={<SvgIcon>
										<Download01 />
									</SvgIcon>} color="primary" variant="contained">
								Download order sheet
							</Button>
						</PDFDownloadLink>}
				</Stack>
				<Box sx={{
        flexGrow: 1
      }} data-sentry-element="Box" data-sentry-source-file="order-sheet-dialog.tsx">
					<PDFViewer height="100%" style={{
          border: "none"
        }} width="100%" data-sentry-element="PDFViewer" data-sentry-source-file="order-sheet-dialog.tsx">
						<OrderSheetDocument itemsSummary={updateFileInputSummary} file={file} company={company} products={products} productOptions={productOptions} data-sentry-element="OrderSheetDocument" data-sentry-source-file="order-sheet-dialog.tsx" />
					</PDFViewer>
				</Box>
			</Box>
		</Dialog>;
};