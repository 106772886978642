import { useQuery } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { useTill } from "~/store";
import { graphql } from "~/types/__generated/gql";
import { GetTillProductCategoriesQuery } from "~/types/__generated/gql/graphql";
import { useGetAgentsCache } from "../useGetAgents";
import { useGetClientsCache } from "../useGetClients";

const query = /* GraphQL */ `
	query GetTillProductCategories {
		getProductCategories {
			_id
			createdAt
			updatedAt
			name

			_products {
				_id
				createdAt
				updatedAt
				name
				description
				category
				type
				originalPrice
				originalPriceAddons {
					vat {
						percentage
						amount
					}
				}
				noVat
				listedPrice
				configuredOptionGroups {
					isRequired
					minimumSelect
					maximumSelect
					optionGroup

					_optionGroup {
						_id
						createdAt
						updatedAt
						name

						_options {
							_id
							createdAt
							updatedAt
							name
							originalPrice
							originalPriceAddons {
								vat {
									percentage
									amount
								}
							}
							listedPrice
						}
					}
				}

				_options {
					_id
					createdAt
					updatedAt
					name
					originalPrice
					originalPriceAddons {
						vat {
							percentage
							amount
						}
					}
					listedPrice
				}
			}
		}
	}
`;

export type GetTillProductCategoriesResults =
	GetTillProductCategoriesQuery["getProductCategories"];

export type TillProductCategory = GetTillProductCategoriesResults[number];
export type TillProduct =
	GetTillProductCategoriesResults[number]["_products"][number];
export type TillProductOption =
	GetTillProductCategoriesResults[number]["_products"][number]["_options"][number];

export const getTillProductCategoriesQueryKey = () => [
	"getTillProductCategories",
];

export const useGetTillProductCategories = (enabled?: boolean) => {
	const { data: agents = [] } = useGetAgentsCache();
	const { data: clients = [] } = useGetClientsCache();
	const { createFileInput, updateFileInput } = useTill();

	const { data: categories = [], ...rest } = useQuery({
		enabled: enabled ?? true,
		queryKey: getTillProductCategoriesQueryKey(),
		queryFn: async () =>
			apiGqlClient
				.request(graphql(query))
				.then((res) => res.getProductCategories),
	});
	let filteredCategories = categories;
	if (createFileInput) {
		const agent = agents.find((agent) => agent._id === createFileInput.agent);
		if (agent) {
			filteredCategories = filteredCategories
				.filter((category) =>
					agent.hiddenProductsConfigs.categories.length
						? !agent.hiddenProductsConfigs.categories.includes(category._id)
						: true,
				)
				.map((category) => {
					return {
						...category,
						_products: category._products
							.filter((product) =>
								agent.hiddenProductsConfigs.products.length
									? !agent.hiddenProductsConfigs.products.includes(product._id)
									: true,
							)
							.filter((product) =>
								agent.hiddenProductsConfigs.types.length
									? !agent.hiddenProductsConfigs.types.includes(product.type)
									: true,
							),
					};
				});
		}
		const client = clients.find(
			(agent) => agent._id === createFileInput.client,
		);
		if (client) {
			filteredCategories = filteredCategories
				.filter((category) =>
					client.hiddenProductsConfigs.categories.length
						? !client.hiddenProductsConfigs.categories.includes(category._id)
						: true,
				)
				.map((category) => {
					return {
						...category,
						_products: category._products
							.filter((product) =>
								client.hiddenProductsConfigs.products.length
									? !client.hiddenProductsConfigs.products.includes(product._id)
									: true,
							)
							.filter((product) =>
								client.hiddenProductsConfigs.types.length
									? !client.hiddenProductsConfigs.types.includes(product.type)
									: true,
							),
					};
				});
		}
	}

	if (updateFileInput) {
		const agent = agents.find((agent) => agent._id === updateFileInput.agent);
		if (agent) {
			filteredCategories = filteredCategories
				.filter((category) =>
					agent.hiddenProductsConfigs.categories.length
						? !agent.hiddenProductsConfigs.categories.includes(category._id)
						: true,
				)
				.map((category) => {
					return {
						...category,
						_products: category._products
							.filter((product) =>
								agent.hiddenProductsConfigs.products.length
									? !agent.hiddenProductsConfigs.products.includes(product._id)
									: true,
							)
							.filter((product) =>
								agent.hiddenProductsConfigs.types.length
									? !agent.hiddenProductsConfigs.types.includes(product.type)
									: true,
							),
					};
				});
		}
		const client = clients.find(
			(agent) => agent._id === updateFileInput.client,
		);
		if (client) {
			filteredCategories = filteredCategories
				.filter((category) =>
					client.hiddenProductsConfigs.categories.length
						? !client.hiddenProductsConfigs.categories.includes(category._id)
						: true,
				)
				.map((category) => {
					return {
						...category,
						_products: category._products
							.filter((product) =>
								client.hiddenProductsConfigs.products.length
									? !client.hiddenProductsConfigs.products.includes(product._id)
									: true,
							)
							.filter((product) =>
								client.hiddenProductsConfigs.types.length
									? !client.hiddenProductsConfigs.types.includes(product.type)
									: true,
							),
					};
				});
		}
	}

	return { ...rest, data: filteredCategories };
};

export const useGetTillProductCategoriesCache = () => {
	return useGetTillProductCategories(false);
};
