import { useQuery } from "@tanstack/react-query";

import { useAppRouter } from "~/hooks/use-app-router";
import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetFileByIdQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetFileById($id: ObjectID!) {
		getFileById(id: $id) {
			_id
			createdAt
			updatedAt
			isProcessed
			products {
				_id
				createdAt
				updatedAt
				originalPrice
				originalPriceAddons {
					vat {
						percentage
						amount
					}
				}
				listedPrice
				unitPrice
				quantity
				subtotal
				subtotalAddons {
					offer {
						metadata
						amount
					}
					discount {
						isDivided
						type
						value
						amount
					}
					vat {
						isIncluded
						percentage
						amount
					}
					adjustment {
						amount
					}
				}
				noVat
				netAmount
				grossAmount
				cancelledAmount
				category
				type
				product
				options {
					_id
					createdAt
					updatedAt
					originalPrice
					originalPriceAddons {
						vat {
							percentage
							amount
						}
					}
					listedPrice
					quantity
					subtotal
					subtotalAddons {
						offer {
							metadata
							amount
						}
						discount {
							isDivided
							type
							value
							amount
						}
						vat {
							isIncluded
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					netAmount
					grossAmount
					cancelledAmount
					option

					_option {
						_id
						name
					}
				}

				_product {
					_id
					name
				}
			}
			cancelledProducts {
				_id
				createdAt
				updatedAt
				originalPrice
				originalPriceAddons {
					vat {
						percentage
						amount
					}
				}
				listedPrice
				unitPrice
				quantity
				subtotal
				subtotalAddons {
					offer {
						metadata
						amount
					}
					discount {
						isDivided
						type
						value
						amount
					}
					vat {
						isIncluded
						percentage
						amount
					}
					adjustment {
						amount
					}
				}
				noVat
				netAmount
				grossAmount
				cancelledAmount
				category
				type
				product
				options {
					_id
					createdAt
					updatedAt
					originalPrice
					originalPriceAddons {
						vat {
							percentage
							amount
						}
					}
					listedPrice
					quantity
					subtotal
					subtotalAddons {
						offer {
							metadata
							amount
						}
						discount {
							isDivided
							type
							value
							amount
						}
						vat {
							isIncluded
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					netAmount
					grossAmount
					cancelledAmount
					option

					_option {
						_id
						name
					}
				}

				_product {
					_id
					name
				}
			}
			process
			company
			user
			client
			agent
			sortingIndex
			assignees
			processPaths {
				process
				leadTime

				_process {
					_id
					name
				}
			}
			processLastUpdatedAt
			customFields {
				customField
				textValue
				selectValues
				dateTimeValue
			}
			counterConfigs {
				counter
				index

				_counter {
					_id
					prefix
				}
			}
			receipt
			isCancelled

			_process {
				_id
				createdAt
				updatedAt
				name
			}
			_user {
				_id
				createdAt
				updatedAt
				firstName
				lastName
				avatar
			}
			_client {
				_id
				createdAt
				updatedAt
				name
				addresses {
					title
					country
					province
					city
					area
					street
					building
				}
				contacts {
					firstName
					lastName
					email
					telephone
					mobile
					gender
					position
				}
			}
			_agent {
				_id
				createdAt
				updatedAt
				name
				_company {
					name
					address
				}
				contacts {
					firstName
					lastName
					mobile
				}
			}
			_assignees {
				_id
				createdAt
				updatedAt
				firstName
				lastName
				avatar
			}
			_receipt {
				_id
				createdAt
				updatedAt
				company
				user
				payments {
					amount
					payment_type
				}
			}
		}
	}
`;

export type GetFileByIdResults = GetFileByIdQuery["getFileById"];

export const getFileByIdQueryKey = (id: string) => ["getFileById", id];

export const useGetFileById = (id?: string, enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? !!id,
		queryKey: getFileByIdQueryKey(id!),
		queryFn: async () =>
			apiGqlClient
				.request(graphql(query), { id: id! })
				.then((res) => res.getFileById),
	});
};

export const useGetFileByIdCache = (id?: string) => {
	return useGetFileById(id, false);
};

export const useGetCurrentFile = () => {
	const { appQuery } = useAppRouter();
	return useGetFileById(appQuery.fileId);
};

export const useGetCurrentFileCache = () => {
	const { appQuery } = useAppRouter();
	return useGetFileByIdCache(appQuery.fileId);
};
