import { useTheme } from "@mui/material/styles";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FC, useMemo } from "react";
import { useTillSummary } from "~/hooks/use-till-summary";
import { $dayjs, DATESTRING_FORMAT } from "~/libs/dayjs";
import { GetCurrentCompanyResults } from "~/queries/useGetCurrentCompany";
import { GetFileByIdResults } from "~/queries/useGetFileById";
import { GetProductOptionsResults } from "~/queries/useGetProductOptions";
import { GetProductsResults } from "~/queries/useGetProducts";
const useStyles = () => {
  const theme = useTheme();
  return useMemo(() => {
    return StyleSheet.create({
      page: {
        backgroundColor: "#FFFFFF",
        padding: 24
      },
      h4: {
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 1.235
      },
      h6: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.6
      },
      alignRight: {
        textAlign: "right"
      },
      subtitle2: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: 1.57
      },
      body2: {
        fontSize: 10,
        fontWeight: 400,
        lineHeight: 1.43
      },
      gutterBottom: {
        marginBottom: 4
      },
      colorSuccess: {
        color: theme.palette.success.main
      },
      colorError: {
        color: theme.palette.error.main
      },
      uppercase: {
        textTransform: "uppercase"
      },
      header: {
        flexDirection: "row",
        justifyContent: "center"
      },
      companies: {
        marginTop: 32
      },
      companyRow: {
        borderBottomWidth: 1,
        borderColor: "#EEEEEE",
        borderStyle: "solid",
        flexDirection: "row"
      },
      companyColLeft: {
        padding: 6,
        width: "50%"
      },
      companyColRight: {
        padding: 6,
        width: "50%"
      },
      items: {
        marginTop: 36
      },
      itemRow: {
        borderBottomWidth: 1,
        borderColor: "#EEEEEE",
        borderStyle: "solid",
        flexDirection: "row"
      },
      itemNumber: {
        padding: 6,
        width: "10%"
      },
      itemDescription: {
        padding: 6,
        width: "70%"
      },
      itemQty: {
        padding: 6,
        width: "20%"
      },
      notes: {
        marginTop: 36
      },
      noteHeader: {
        textAlign: "center",
        justifyContent: "center"
      },
      noteRow: {
        border: 1,
        borderColor: "#EEEEEE",
        borderStyle: "solid",
        flexDirection: "row",
        padding: 6
      }
    });
  }, [theme]);
};
export const OrderSheetDocument: FC<{
  file: GetFileByIdResults;
  company: GetCurrentCompanyResults;
  itemsSummary: ReturnType<typeof useTillSummary>["updateFileInputSummary"];
  products: GetProductsResults;
  productOptions: GetProductOptionsResults;
}> = ({
  file,
  company,
  itemsSummary,
  products,
  productOptions
}) => {
  const styles = useStyles();
  return <Document data-sentry-element="Document" data-sentry-component="OrderSheetDocument" data-sentry-source-file="order-sheet-document.tsx">
			<Page size="A4" style={styles.page} data-sentry-element="Page" data-sentry-source-file="order-sheet-document.tsx">
				<View style={styles.header} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
					<View data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<Text style={styles.h4} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">ORDER SHEET</Text>
					</View>
				</View>
				<View style={styles.companies} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
					<View style={styles.companyRow} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<View style={styles.companyColLeft} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">{company.name}</Text>
						</View>
						<View style={styles.companyColRight} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">
								{file._user.firstName} {file._user.lastName}
							</Text>
						</View>
					</View>

					<View style={styles.companyRow} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<View style={styles.companyColLeft} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">{file._client.name}</Text>
						</View>
						<View style={styles.companyColRight} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">assignee team</Text>
						</View>
					</View>

					<View style={styles.companyRow} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<View style={styles.companyColLeft} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">
								{file._agent?._company.name ?? "UNKNOWN AGENT COMPANY"}
							</Text>
						</View>
						<View style={styles.companyColRight} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">
								{$dayjs(file.createdAt).add(99, "y").format(DATESTRING_FORMAT)}
							</Text>
						</View>
					</View>

					<View style={styles.companyRow} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<View style={styles.companyColLeft} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">
								{`${file._agent?.contacts[0].firstName ?? "UNKNOWN AGENT"} ${file._agent?.contacts[0].lastName ?? "UNKNOWN AGENT"}`}
							</Text>
						</View>
						<View style={styles.companyColRight} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">
								{$dayjs().add(99, "y").format(DATESTRING_FORMAT)}
							</Text>
						</View>
					</View>

					<View style={styles.companyRow} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<View style={styles.companyColLeft} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">
								{file._agent?.contacts[0].mobile ?? "UNKNOWN AGENT"}
							</Text>
						</View>
						<View style={styles.companyColRight} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx"></View>
					</View>

					<View style={styles.companyRow} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<View style={styles.companyColLeft} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">
								{file._agent?._company.address ?? "UNKNOWN AGENT COMPANY"}
							</Text>
						</View>
						<View style={styles.companyColRight} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx"></View>
					</View>
				</View>

				<View style={styles.items} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
					<View style={styles.itemRow} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<View style={styles.itemNumber} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.h6} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">#</Text>
						</View>
						<View style={styles.itemDescription} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.h6} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">Product</Text>
						</View>
						<View style={styles.itemQty} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
							<Text style={styles.h6} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">Qty</Text>
						</View>
					</View>
					{itemsSummary.products.map((item, index) => {
          return <View key={item._id.toString()} style={styles.itemRow}>
								<View style={styles.itemNumber}>
									<Text style={styles.body2}>{index + 1}</Text>
								</View>
								<View style={styles.itemDescription}>
									<Text style={styles.body2}>
										{products.find(p => p._id === item.product)?.name ?? "[UNKNOWN PRODUCT]"}
									</Text>
									<Text style={styles.body2}>
										{item.options.map(option => productOptions.find(p => p._id === option.option)?.name ?? "[UNKNOWN PRODUCT OPTION]").join(", ")}
									</Text>
								</View>
								<View style={styles.itemQty}>
									<Text style={styles.body2}>{item.quantity}</Text>
								</View>
							</View>;
        })}
				</View>
				<View style={styles.notes} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
					<View style={[styles.noteRow, styles.noteHeader]} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">Notes</Text>
					</View>
					<View style={styles.noteRow} data-sentry-element="View" data-sentry-source-file="order-sheet-document.tsx">
						<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="order-sheet-document.tsx">
							Please make sure you have the right bank registration number as I
							had issues before and make sure you guys cover transfer expenses.
						</Text>
					</View>
				</View>
			</Page>
		</Document>;
};