import { useMemo } from "react";

import { calculateFile } from "~served/utils/calculations";

import { useTillCreateFileInput, useTillUpdateFileInput } from "~/store";

export const useTillSummary = () => {
	const createFileInput = useTillCreateFileInput();
	const updateFileInput = useTillUpdateFileInput();

	const createFileInputSummary = useMemo(
		() =>
			calculateFile({
				products: createFileInput?.products ?? [],
				cancelledProducts: createFileInput?.cancelledProducts ?? [],
			}),
		[createFileInput],
	);

	const updateFileInputSummary = useMemo(
		() =>
			calculateFile({
				products: updateFileInput?.products ?? [],
				cancelledProducts: updateFileInput?.cancelledProducts ?? [],
			}),
		[updateFileInput],
	);

	return {
		createFileInputSummary,
		updateFileInputSummary,
	};
};
