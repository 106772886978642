import { AppBar, Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, Unstable_Grid2 as Grid, Stack, Toolbar, Typography } from "@mui/material";
import { ObjectID } from "bson";
import { pick } from "lodash";
import { useMemo } from "react";
import { FileProductInputLike } from "~served/utils/calculations/types";
import { ProductsTableSummary, TableItem } from "~/components/products-table-summary";
import { useAppRouter } from "~/hooks/use-app-router";
import { useTillSelectedCategory } from "~/hooks/use-till-selected-category";
import { useTillSummary } from "~/hooks/use-till-summary";
import { useGetProductOptions } from "~/queries/useGetProductOptions";
import { useGetProducts } from "~/queries/useGetProducts";
import { TillProduct, useGetTillProductCategories } from "~/queries/useGetTillProductCategories";
import { UpdateFileVariables, useUpdateFile } from "~/queries/useUpdateFile";
import { useSetTillIsOpen, useSetTillSelectedCategoryId, useTill, useUpdateTillCreateFileInput, useUpdateTillTempProductInput, useUpdateTillUpdateFileInput } from "~/store";
import { FileProductInput } from "~/types/__generated/gql/graphql";
import { formatCurrency } from "~/utils/format-currency";
import { TempProductModal } from "./temp-product-modal";
export const TillModal = () => {
  const {
    selectedCategoryId,
    selectedProductId,
    createFileInput,
    updateFileInput
  } = useTill();
  const setTillIsOpen = useSetTillIsOpen();
  const setSelectCategoryId = useSetTillSelectedCategoryId();
  const updateCreateFileInput = useUpdateTillCreateFileInput();
  const updateTempProduct = useUpdateTillTempProductInput();
  const {
    appQuery: {
      fileId
    },
    currentActions: {
      isViewingFile,
      isCreatingFile
    }
  } = useAppRouter();
  const {
    createFileInputSummary,
    updateFileInputSummary
  } = useTillSummary();
  const {
    data: categories = []
  } = useGetTillProductCategories();
  const selectedCategory = useTillSelectedCategory();
  const updateFile = useUpdateFile();
  const updateUpdateFileInput = useUpdateTillUpdateFileInput();
  const handleClose = async () => {
    if (isViewingFile) {
      await updateFile.mutateAsync({
        id: fileId!,
        input: {
          products: updateFileInputSummary.products as UpdateFileVariables["input"]["products"]
        }
      });
    }
    setTillIsOpen(false);
  };
  const addProductToCreateInput = (product: TillProduct) => {
    const isAlreadyAdded = createFileInput!.products.some(p => p.product === product._id);
    if (isAlreadyAdded) {
      const newProducts = createFileInput!.products.map(p => p.product === product._id ? {
        ...p,
        quantity: p.quantity + 1
      } : p);
      updateCreateFileInput({
        products: newProducts
      });
    } else {
      const newProduct: FileProductInputLike = {
        ...pick(product, ["noVat", "originalPrice", "originalPriceAddons", "listedPrice", "type", "category"]),
        _id: new ObjectID().toString(),
        product: product._id,
        quantity: 1,
        options: []
      };
      updateCreateFileInput({
        products: [...createFileInput!.products, newProduct as FileProductInput]
      });
    }
  };
  const addProductToUpdateInput = (product: TillProduct) => {
    const isAlreadyAdded = updateFileInput!.products.some(p => p.product === product._id);
    if (isAlreadyAdded) {
      const newProducts = updateFileInput!.products.map(p => p.product === product._id ? {
        ...p,
        quantity: p.quantity + 1
      } : p);
      updateUpdateFileInput({
        products: newProducts
      });
    } else {
      const newProduct: FileProductInputLike = {
        ...pick(product, ["noVat", "originalPrice", "originalPriceAddons", "listedPrice", "type", "category"]),
        _id: new ObjectID().toString(),
        product: product._id,
        quantity: 1,
        options: []
      };
      updateUpdateFileInput({
        products: [...updateFileInput!.products, newProduct as FileProductInput]
      });
    }
  };
  const addProductToInput = (product: TillProduct) => {
    if (isCreatingFile) {
      addProductToCreateInput(product);
    }
    if (isViewingFile) {
      addProductToUpdateInput(product);
    }
  };
  const onProductClick = (product: TillProduct) => {
    const hasNoOptions = !product._options.length && !product.configuredOptionGroups.length;
    if (hasNoOptions) {
      addProductToInput(product);
    } else {
      updateTempProduct({
        ...pick(product, ["noVat", "originalPrice", "originalPriceAddons", "listedPrice", "type", "category"]),
        _id: new ObjectID().toString(),
        product: product._id,
        quantity: 1,
        options: []
      });
    }
  };
  const {
    data: products = []
  } = useGetProducts();
  const {
    data: productOptions = []
  } = useGetProductOptions();
  const createFileInputItems = useMemo<TableItem[]>(() => createFileInputSummary.products.map(product => ({
    id: product._id.toString(),
    name: products.find(p => p._id === product.product)?.name ?? "[UNKNOWN PRODUCT]",
    quantity: product.quantity,
    subtotal: product.subtotal,
    options: product.options.map(option => ({
      id: option._id.toString(),
      name: productOptions.find(p => p._id === option.option)?.name ?? "[UNKNOWN PRODUCT OPTION]",
      quantity: option.quantity,
      subtotal: option.subtotal
    }))
  })), [createFileInputSummary.products, productOptions, products]);
  const updateFileInputItems = useMemo<TableItem[]>(() => updateFileInputSummary.products.map(product => ({
    id: product._id.toString(),
    name: products.find(p => p._id === product.product)?.name ?? "[UNKNOWN PRODUCT]",
    quantity: product.quantity,
    subtotal: product.subtotal,
    options: product.options.map(option => ({
      id: option._id.toString(),
      name: productOptions.find(p => p._id === option.option)?.name ?? "[UNKNOWN PRODUCT OPTION]",
      quantity: option.quantity,
      subtotal: option.subtotal
    }))
  })), [updateFileInputSummary.products, productOptions, products]);
  return <>
			<TempProductModal data-sentry-element="TempProductModal" data-sentry-source-file="index.tsx" />

			<Dialog open fullWidth maxWidth={false} data-sentry-element="Dialog" data-sentry-source-file="index.tsx">
				<Stack sx={{
        height: "100dvh",
        overflow: "auto"
      }} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
					<AppBar color="inherit" sx={{
          position: "relative"
        }} data-sentry-element="AppBar" data-sentry-source-file="index.tsx">
						<Toolbar data-sentry-element="Toolbar" data-sentry-source-file="index.tsx">
							<Typography sx={{
              ml: 2,
              flex: 1
            }} variant="h6" component="div" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
								Products
							</Typography>
							<Button variant="contained" onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="index.tsx">
								Done
							</Button>
						</Toolbar>
					</AppBar>
					<Grid container spacing={3} p={3} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
						<Grid xs={12} md={4} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
							<Grid container spacing={3} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
								{categories.map(category => <Grid key={category._id} xs={12} md={4}>
										<Card variant="outlined" sx={[{
                  cursor: "pointer",
                  ":hover": {
                    boxShadow: t => t.shadows[8]
                  }
                }, category._id === selectedCategoryId && {
                  boxShadow: t => t.shadows[8],
                  borderColor: "primary.main",
                  backgroundColor: "primary.main",
                  color: t => t.palette.primary.contrastText,
                  ".MuiCardHeader-subheader": {
                    color: t => t.palette.primary.contrastText
                  }
                }]} onClick={() => setSelectCategoryId(category._id)}>
											<CardHeader title={category.name} subheader={`${category._products.length} products`} />
											<CardContent />
											<CardActions />
										</Card>
									</Grid>)}
							</Grid>
						</Grid>
						<Grid xs={12} md={4} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
							<Grid container spacing={3} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
								{!!selectedCategory && selectedCategory._products.map(product => <Grid key={product._id} xs={12} md={4}>
											<Card variant="outlined" sx={[{
                  cursor: "pointer",
                  ":hover": {
                    boxShadow: t => t.shadows[8]
                  }
                }, product._id === selectedProductId && {
                  boxShadow: t => t.shadows[8],
                  borderColor: "secondary.main",
                  backgroundColor: "secondary.main",
                  color: t => t.palette.primary.contrastText,
                  ".MuiCardHeader-subheader": {
                    color: t => t.palette.primary.contrastText
                  }
                }]} onClick={() => onProductClick(product)}>
												<CardHeader title={product.name} subheader={formatCurrency(product.listedPrice)} />
												<CardContent />
												<CardActions />
											</Card>
										</Grid>)}
								{!!selectedCategory && !selectedCategory._products.length && <Grid xs={12} width="100%" textAlign="center">
										<Box alt="Not found" component="img" src="/assets/errors/error-404.png" sx={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "200px",
                  mx: "auto",
                  mb: 2
                }} />
										<Typography variant="h6">This category is empty</Typography>
									</Grid>}
							</Grid>
						</Grid>
						<Grid xs={12} md={4} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
							{isCreatingFile && <ProductsTableSummary items={createFileInputItems} grandTotal={createFileInputSummary.grossAmount} onRowClick={item => {
              const inputItem = createFileInput!.products.find(p => p._id === item.id);
              if (inputItem) updateTempProduct(inputItem);
            }} />}
							{isViewingFile && <ProductsTableSummary items={updateFileInputItems} grandTotal={updateFileInputSummary.grossAmount} onRowClick={item => {
              const inputItem = updateFileInput!.products.find(p => p._id === item.id);
              if (inputItem) updateTempProduct(inputItem);
            }} />}
						</Grid>
					</Grid>
				</Stack>
			</Dialog>
		</>;
};